<template>
  <div class="article_item_container article_item_container">
    <div class="article_item_content_block">
      <div class="article_item_content">
        <!-- 置顶 emphasis == 1-->
        <img
          style="height: 18px"
          class="article_item_number"
          :src="require('@/assets/img/top.png')"
          v-if="item?.top == 1 && type == 'articleList'"
        />
        <!-- 序号 -->
        <span
          class="article_item_number"
          v-if="item.top == 0 && type == 'articleList'"
          >{{ item.location }}.</span
        >
        <span class="article_item_number" v-if="type == 'collectList'"
          >{{ index + 1 }}.</span
        >
        <!-- 标题 -->
        <div class="article_item_title" v-html="item.head_title"></div>
      </div>
      <div class="article_item_tag_block">
        <div class="article_item_tag" style="color: #b5b5b5">
          {{ item.day_type }}
        </div>
        <div class="article_item_tag">
          <!-- :style="`background:${item.tag == '原文' ? '#132460' : '#009d7d'}`" -->
          {{ item?.tag }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articleItem",
  components: {},
  props: {
    item: Object,
    index: Number,
    type: String,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
@import "../../../assets/icon/iconfont.css";
.article_item_container {
  width: 100%;
  /* height: 42px; */
  font-size: 29px;
  margin-bottom: 12px;
  /* border-bottom: 1px solid #cccaca; */
}
.article_item_content_block {
}
.article_item_container .article_item_content {
  height: 100%;
  display: flex;
  /* align-items: center; */
}

.article_item_container .article_item_number {
  font-weight: bold;
  font-size: 14px;
  /* color: #009d7d; */
  margin-right: 8px;
  width: 17px;
  height: 18px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.article_item_container .article_item_title {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  flex: 1 0 0;
  font-weight: bold;
  line-height: 21px;
}
.article_item_tag_block {
  display: flex;
  margin: 4px 24px;
  justify-content: space-between;
}
.article_item_tag {
  border-radius: 10px;
  font-size: 11px;
  color: #009d7d;
  text-align: center;
  /* margin-right: 10px; */
}
</style>
