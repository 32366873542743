<template>
  <div class="loading_container full-screen global-center">
    <i class="iconfont icon-loading loading_button"></i>
  </div>
</template>
<script>
import "@/assets/icon/iconfont.css";
export default {
  name: "loading",
};
</script>
<style scoped>
.loading_container {
  margin-top: -2.25rem;
}
.loading_button {
  font-size: 2rem;
  color: #126de3;
  animation: round 1s infinite;
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
